<template>
  <div class="drawingboard">
      This is the main component were you would connect things together
      <DrawingSpace />
  </div>
</template>

<script>
// @ is an alias to /src
import DrawingSpace from '@/components/DrawingSpace.vue'

export default {
  name: 'DrawingBoard',
  components: {
      DrawingSpace
  }
}
</script>
