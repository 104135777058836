<template>
  <div class="drawingspace">
    <div v-if="changed[0]">not saved...</div>
    <div v-else>saved</div>
    {{ changed[1] }} ms
      <div draggable @dragstart="startNewDrag($event)" class='drag-el new-card'>New Card</div>
      <button v-on:click="save">Save</button>
      <div class='drop-zone' @drop="onDrop($event, 1)" @dragover.prevent @dragenter.prevent >
          <div v-for="card in cards" v-bind:key="card.id" class='drag-el card-holder' 
            draggable @dragstart="startDrag($event, card)"
            v-bind:style="{left: card.layout.x+'px', top: card.layout.y+'px'}">
              <Card v-bind:card="card" />
        </div>
      </div>
  </div>
</template>

<script>
import Card from '@/components/Card.vue'  
export default {
  name: 'drawingspace',
  components: {
      Card,
  },
  computed: {
    changed: function() {
      // NOTE: This seems to work fine but I suppose it could become to heavy at some point
      // with a lot of cards. 
      let t0 = new Date().getTime();
      let res = JSON.stringify(this.cards) != this.lastSaved
      let dt = (new Date().getTime() - t0)
      return [res, dt]
    }
  },
  data: function() {
      return {
          ...this.mapAuthState(),
          lastSaved: JSON.stringify([]),
          cards: [],
      }
  },
  created() {
        this.load()
        this.saveTimer = setInterval(this.maybeSave, 10000);
  },
  beforeDestroy() {
      console.log("removing save timer...")
      clearInterval(this.saveTimer)
  },
  methods: {
    maybeSave() {
      if (this.changed[0]) {
        console.log("autosaving...")
        this.save();
      }

    },
    load() {
          const requestOptions = {
            method: "GET",
            headers: {
              "authorization": "Bearer " + this.authState.user.accessToken             
            },
          };
          fetch("https://sfs.int.ovaielo.se/api/1/ovaielo/systemdesign/designs/example", requestOptions)
          .then(response => response.json())
          .then(data => {
            this.cards = data;
            this.lastSaved = JSON.stringify(data);
          });

    },
        save() {
          const requestOptions = {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "authorization": "Bearer " + this.authState.user.accessToken             
            },
            body: JSON.stringify(this.cards)
          };
          fetch("https://sfs.int.ovaielo.se/api/1/ovaielo/systemdesign/designs/example", requestOptions)
          .then(() => {
            this.lastSaved = JSON.stringify(this.cards)
          })
        },
        addNewCard() {
            let newId = Math.max(...this.cards.map(c => c.id)) + 1
            let newCard = {
                "id": newId,
                "description": "fill in description",
                "level": 1,
                "layout": {
                    x: 100,
                    y: 0
                }      
            }
            this.cards.push(newCard)
            return newCard;
            
        },
        startNewDrag(event) {
            event.dataTransfer.dropEffect = 'move'
            event.dataTransfer.effectAllowed = 'move'
            event.dataTransfer.setData('itemID', 'new')
        },
        startDrag(event, item) {
            console.log("dragging")
            event.dataTransfer.dropEffect = 'move'
            event.dataTransfer.effectAllowed = 'move'
            event.dataTransfer.setData('itemID', item.id)
            event.dataTransfer.setData('xStart', event.clientX);
            event.dataTransfer.setData('yStart', event.clientY);
        },
        onDrop(event) {
            let id = event.dataTransfer.getData('itemID')
            if (id == "new") {
                let card = this.addNewCard();
                card.layout.x = event.offsetX;
                card.layout.y = event.offsetY;
            } else {
                let dx = event.clientX - event.dataTransfer.getData('xStart');
                let dy = event.clientY - event.dataTransfer.getData('yStart');
                let item = this.cards.find(c => c.id == id);
                item.layout.x += dx
                item.layout.y += dy
            }

        }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.drop-zone {
    background-color: #eee;
    margin-bottom: 10px;
    padding: 10px;
    height: 800px;
    width: 100%;
    position: relative;
  }

.drag-el {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 5px;
    position: relative;
}

.card-holder {
    width: 300px;
    height: 200px;
    position: absolute;
    border-radius: 10px;
    padding: 0px;
}
.new-card {
    border: 1px solid black;
    border-radius: 5px;
    width: 100px;
}
</style>
