<template>
  <div id="app">
    <p v-if="this.authState.user">
      <router-link to="/about">About</router-link> |
      <router-link to="/drawingboard">Drawing Board</router-link> ---
      Logged in as
      {{ this.authState.user.email }}
      <button v-on:click="showAdminPortal">My Account</button>
       <button v-on:click="logout">Logout</button>
    </p>
    <p v-else>
       <button v-if="!this.authState.user" v-on:click="loginWithRedirect">Login</button>
       <!-- <button v-if="!this.authState.user" v-on:click="signup">Sign Up!</button> -->
    </p>
    <router-view v-if="this.authState.user"/>
  </div>
</template>

<script>

import { mapLoginActions } from "@frontegg/vue";
import { AdminPortal } from "@frontegg/vue";

export default {
  name: "App",
  data() {
    return {
      ...this.mapAuthState(),
      };

  },
  methods: {
    loginWithRedirect: mapLoginActions('loginWithRedirect'),
    logoutWithRedirect: mapLoginActions('logoutWithRedirect'),
    goToLogin() {
      this.$router.push('/account/login');
    },
    logout() {
      this.$router.push('/account/logout');
    },
    signup() {
      this.$router.push('/account/sign-up');

    },
    showAdminPortal() {
      AdminPortal.show();
    },
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
