<template>
  <div class="card">
      {{ card.id }}
      <textarea v-model="card.description" />
  </div>
</template>

<script>
export default {
  name: 'card',
  props: {
      card: Object
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
    border: 2px solid black;
    border-radius: 10px;
    width: 100%;
    height: 100%;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

textarea {
    width: 96%;
    height: 80%;
    resize: none;
    border-radius: 5px;
    border: 1px solid gray;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
</style>
